import { Injectable } from '@angular/core';
import { CustomExtraAppConfig } from '../custom-extra-app-config';

/**
 * Utility service on top of the custom extra app config.
 * Provides handy defaults, when not everything is set in the configuration.
 * Use this service instead of direct configuration.
 */
@Injectable({
  providedIn: 'root',
})
export class CustomExtraAppConfigService {
  constructor(
    protected customExtraAppConfig: CustomExtraAppConfig,
  ) {}

  /**
   * Utility to make access to config easier.
   */
  private get config(): CustomExtraAppConfig['customExtraApp'] {
    return this.customExtraAppConfig?.customExtraApp ?? {};
  }

  /**
   * Get enabledCustomerRegister
   *
   * @return enabledCustomerRegister
   */
  public isEnabledCustomerRegister(): boolean {
    return Boolean(this.config.enabledCustomerRegister);
  }

  /**
   * Get allowFriends
   *
   * @return allowFriends
   */
  public allowFriends(): boolean {
    return Boolean(this.config.allowFriends);
  }

  /**
   * Returns if has to display more information in login page.
   *
   * @return extendedLoginInfo
   */
  public showExtendedLoginInfo(): boolean {
    return Boolean(this.config.extendedLoginInfo);
  }

  /**
   * Returns if has to handle billing in first checkout step.
   *
   * @return extendedLoginInfo
   */
  public checkoutBilling(): boolean {
    return Boolean(this.config.checkoutBilling);
  }

  /**
   * Enable mini cart preview or go to cart instead.
   *
   * @return miniCartPreview
   */
  public miniCartPreview(): boolean {
    return  Boolean(this.config.miniCartPreview);
  }

  /**
   * Enable show age popup.
   *
   * @return showAgePopup
   */
  public showAgePopup(): boolean {
    return  Boolean(this.config.showAgePopup);
  }
}
