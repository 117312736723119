import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Inject,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';

import { SearchBoxConfig } from './search-box.model';
import { CmsComponentData, SearchBoxComponent, SearchBoxComponentService } from '@spartacus/storefront';
import { CmsSearchBoxComponent, RoutingService, WindowRef } from '@spartacus/core';
import { isPlatformBrowser } from '@angular/common';

const SEARCHBOX_IS_ACTIVE = 'searchbox-is-active';
const DEFAULT_SEARCH_BOX_CONFIG: SearchBoxConfig = {
  minCharactersBeforeRequest: 1,
  displayProducts: true,
  displaySuggestions: true,
  maxProducts: 5,
  maxSuggestions: 5,
  displayProductImages: true,
};

@Component({
  selector: 'cx-searchbox',
  templateUrl: './search-box.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomSearchBoxComponent extends SearchBoxComponent {
  constructor(
    searchBoxComponentService: SearchBoxComponentService,
    componentData: CmsComponentData<CmsSearchBoxComponent>,
    @Inject(PLATFORM_ID)  private platformId: Object,
    winRef: WindowRef,
    routingService: RoutingService,
    private cd: ChangeDetectorRef

  ) {
    super(searchBoxComponentService, componentData, winRef, routingService);
    this.bodyElement = this.winRef.document.getElementsByTagName('body')[0];
  }
  private bodyElement: HTMLElement;
  isHidden: any;
  isBrowser: boolean = false;
  isResetIcon: boolean = false;
  showIcon: any;
  showReset: boolean = false;
  @ViewChild('searchInput') searchInput: ElementRef;
  @ViewChild('searchIcon') searchIcon: ElementRef;
  @ViewChild('searchResults') searchResults: ElementRef;
  @HostListener('window:scroll', [])


  onWindowScroll() {
    this.isBrowser = isPlatformBrowser(this.platformId);
    const scrollY = window.scrollY;
    const hideThreshold = 1;
    const isInputFilled = this.searchInput.nativeElement.value.trim() !== '';
    this.bodyElement.classList.remove( 'searchbox-is-active');

    let mobileCheck = window.innerWidth > 992;
    if (mobileCheck) {
      if (scrollY > hideThreshold && isInputFilled) {
        this.clear(this.searchInput.nativeElement);
      } else if (scrollY > hideThreshold) {
        this.isHidden = true;
      } else {
        this.isHidden = false;
      }
    } else {
        this.searchIcon.nativeElement.addEventListener('click', () => {
          this.searchInput.nativeElement.value = '';
          this.isHidden = false;
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
          });
        });
    }
  }
  open(): void {
    this.searchBoxComponentService.toggleBodyClass(SEARCHBOX_IS_ACTIVE, true);
  }
  clear(el: HTMLInputElement): void {
    this.disableClose();
    el.value = '';
    this.searchBoxComponentService.clearResults();
    this.isHidden = true;
  }

  close(event: UIEvent, force?: boolean): void {
    const target = event.target as HTMLElement;
    const isSearchResult = this.searchResults?.nativeElement.contains(target);
    if (isSearchResult && !force) {
      return;
    }
  }

  handleKeyDown(event: KeyboardEvent) {
    if (event.key === 'Backspace') {
      const inputValue = this.searchInput.nativeElement.value;
      if (inputValue === '') {
        this.bodyElement.classList.remove('has-searchbox-results', 'searchbox-is-active');
        this.searchResults.nativeElement.style.display = 'block';
        this.isHidden = true;
        this.cd.detectChanges();
        event.preventDefault();
      }
    }
  }

  searchPressed() {
    this.bodyElement.classList.remove('has-searchbox-results', 'searchbox-is-active');
    this.isHidden = !this.isHidden;
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
    if (this.searchInput) {
      this.isResetIcon = true;
      this.isHidden = false;
      this.bodyElement.classList.remove('has-searchbox-results', 'searchbox-is-active');
      this.searchInput.nativeElement.focus();
      this.searchInput.nativeElement.value = '';
      this.searchInput.nativeElement.placeholder = 'Escribe lo que quieres buscar';
    }
  }

  protected override getResultElements(): HTMLElement[] {
    return Array.from(
      this.winRef.document.querySelectorAll(
        ".products > li a, .suggestions > li a, .recent-searches > li a"
      )
    );
  }
}
