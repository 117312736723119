import { Injectable, OnDestroy } from '@angular/core';
import { BaseSiteService, CaptchaConfig, LanguageService, ScriptLoader, SiteAdapter } from '@spartacus/core';
import { CaptchaApiConfig, CaptchaRenderer, RenderParams } from '@spartacus/storefront';
import { Observable, ReplaySubject, Subscription, from, map, of } from 'rxjs';
import { catchError, concatMap, filter, switchMap, take, tap } from 'rxjs/operators';
import { OsborneCaptchaLoaderService } from './captcha-loader.service';

@Injectable({
  providedIn: 'root',
})
export class OsborneCaptchaService implements CaptchaRenderer, OnDestroy {
  protected token: string = '';
  protected subscription = new Subscription();
  protected captchaConfigSubject$ = new ReplaySubject<CaptchaConfig>(1);

  constructor(
    protected adapter: SiteAdapter,
    protected apiConfig: CaptchaApiConfig,
    protected languageService: LanguageService,
    protected scriptLoader: ScriptLoader,
    protected baseSiteService: BaseSiteService,
    private readonly captchaLoaderService: OsborneCaptchaLoaderService,
  ) {
    this.initialize();
  }

  /**
   * Retrieve captcha configuration from the backend, and if enabled
   * call @function loadScript with active language
   */
  initialize(): void {
    this.subscription.add(
      this.fetchCaptchaConfigFromServer()
        .pipe(
          filter((config) => !!config.publicKey),
          take(1),
        )
        .subscribe((config) => {
          if (config.enabled) {
            this.captchaLoaderService.loadScript(config.publicKey!);
            this.captchaConfigSubject$.next(config);
          } else {
            this.captchaConfigSubject$.next({ enabled: false });
          }
        }),
    );
  }

  private fetchCaptchaConfigFromServer(): Observable<CaptchaConfig> {
    return this.baseSiteService.getActive().pipe(
      concatMap((value) => this.adapter.loadBaseSite(value)),
      take(1),
      map((result) => result?.captchaConfig as CaptchaConfig),
    );
  }

  /**
   * @deprecated This method does not suport reCAPTCHAv3. Use renderCaptchaV3 instead.
   */
  renderCaptcha(params: RenderParams): Observable<string> {
    throw new Error('This method does not suport reCAPTCHAv3. Use renderCaptchaV3 instead.');
  }

  renderCaptchaV3(action: string): Observable<string> {
    return this.getCaptchaConfig().pipe(
      map((config) => config.publicKey),
      filter((a) => !!a),
      take(1),
      switchMap((apiKey) =>
        from(grecaptcha.execute(apiKey!, { action })).pipe(
          catchError(() => of('')),
          take(1),
          tap((token) => (this.token = token)),
        ),
      ),
    );
  }

  getCaptchaConfig(): Observable<CaptchaConfig> {
    return this.captchaConfigSubject$.asObservable();
  }

  getToken(): string {
    return this.token ?? '';
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
