import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomFormValidators } from '@spartacus/storefront';
import { RegisterComponent, RegisterComponentService } from '@spartacus/user/profile/components';
import { CustomValidators } from 'src/app/spartacus/custom/shared/utils/validators/custom-validators';
import {
  AnonymousConsentsConfig,
  AnonymousConsentsService,
  AuthConfigService,
  GlobalMessageService,
  isNotNullable,
  RoutingService,
} from '@spartacus/core';
import { UserRegisterFacade } from '@spartacus/user/profile/root';
import { CustomUserEventBuilder } from "../../../../../../features/tracking/custom-events/user/custom-user-event.builder";
import { OsborneCaptchaService } from 'src/app/custom/shared/captcha/captcha.service';
import { take, filter } from 'rxjs';

@Component({
  selector: 'cx-register',
  templateUrl: './custom-register.component.html',
})
export class CustomRegisterComponent extends RegisterComponent {

  recaptchaEnabled = true;
  widgetId: any;
  // Max name and last name combined length must be 40, but when joining them there will be 1 space in between
  private completeNameMaxLength = 39;

  constructor(
    userRegister: UserRegisterFacade,
    globalMessageService: GlobalMessageService,
    fb: FormBuilder,
    router: RoutingService,
    anonymousConsentsService: AnonymousConsentsService,
    anonymousConsentsConfig: AnonymousConsentsConfig,
    authConfigService: AuthConfigService,
    private readonly captchaService: OsborneCaptchaService,
    protected customUserEventBuilder: CustomUserEventBuilder,
    protected registerComponentService: RegisterComponentService
  ) {
    super(
      globalMessageService,
      fb,
      router,
      anonymousConsentsService,
      anonymousConsentsConfig,
      authConfigService,
      registerComponentService
    );

  }

  registerForm: FormGroup = this.fb.group(
    {
      firstName: ['', [Validators.required, CustomValidators.combinedMaxLength('lastName', this.completeNameMaxLength)]],
      lastName: ['', [Validators.required, CustomValidators.combinedMaxLength('firstName', this.completeNameMaxLength)]],
      email: ['', [Validators.required, Validators.maxLength(60), CustomValidators.emailValidator]],
      password: ['', [Validators.required, CustomValidators.passwordValidator]],
      passwordconf: ['', Validators.required],
      newsletter: [true],
      termsandconditions: [false, Validators.requiredTrue],
    },
    {
      validators: CustomFormValidators.passwordsMustMatch(
        'password',
        'passwordconf',
      ),
    },
  );

  onChangeCompleteName(): void {
    this.registerForm['controls'].firstName?.updateValueAndValidity();
    this.registerForm['controls'].lastName?.updateValueAndValidity();
  }

  submitForm(): void {
    if (this.registerForm.valid) {
      this.captchaService
      .getCaptchaConfig()
      .pipe(take(1))
      .subscribe((config) => {
        if (config.enabled && config.publicKey) {
          this.captchaService
            .renderCaptchaV3('register')
            .pipe(filter(isNotNullable), take(1))
            .subscribe((token) => {
              if (token) {
                this.customUserEventBuilder.setCurrentUid(this.registerForm['controls'].email.value);
                this.registerUser();
              }
            });
        } else {
          this.customUserEventBuilder.setCurrentUid(this.registerForm['controls'].email.value);
          this.registerUser();
        }
      });
    } else {
      this.registerForm.markAllAsTouched();
    }
  }

  getWidgetItemId(value): void {
    this.widgetId = value;
  }
}


