import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";
import { I18nModule, FeaturesConfigModule, CmsConfig, provideConfig } from "@spartacus/core";
import { IconModule, FormErrorsModule, PopoverModule } from "@spartacus/storefront";
import { CustomCartCouponComponent } from "./custom-cart-coupon.component";
import { CustomAppliedCouponsComponent } from "./custom-applied-coupons/custom-applied-coupons.component";

@NgModule({
  declarations: [CustomCartCouponComponent, CustomAppliedCouponsComponent],
  exports: [CustomCartCouponComponent, CustomAppliedCouponsComponent],
  imports: [
    CommonModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    I18nModule,
    IconModule,
    FormErrorsModule,
    FeaturesConfigModule,
    PopoverModule,
  ],
  providers: [
    provideConfig(<CmsConfig> {
      cmsComponents: {
        CartApplyCouponComponent: {
          component: CustomCartCouponComponent,
        },
      }
    }),
  ],
})
export class CustomCartCouponModule {}
