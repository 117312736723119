import { Component, inject, OnInit, Optional } from "@angular/core";
import { Validators } from "@angular/forms";
import { CartCouponComponent } from "@spartacus/cart/base/components";
import { Cart } from "@spartacus/cart/base/root";
import { CustomerCouponSearchResult, FeatureConfigService } from "@spartacus/core";
import { combineLatest } from "rxjs";
import { tap, map } from "rxjs/operators";

@Component({
  selector: 'cx-cart-coupon',
  templateUrl: './custom-cart-coupon.component.html',
})
export class CustomCartCouponComponent extends CartCouponComponent implements OnInit {

  @Optional() protected featureConfigService = inject(FeatureConfigService, {
    optional: true,
  });
  
  override ngOnInit() {
    if (this.customerCouponService) {
      this.customerCouponService.loadCustomerCoupons(
        this.MAX_CUSTOMER_COUPON_PAGE
      );
    }

    this.cart$ = combineLatest([
      this.activeCartService.getActive(),
      this.activeCartService.getActiveCartId(),
      this.customerCouponService.getCustomerCoupons(
        this.MAX_CUSTOMER_COUPON_PAGE
      ),
    ]).pipe(
      tap(
        ([cart, activeCardId, customerCoupons]: [
          Cart,
          string,
          CustomerCouponSearchResult,
        ]) => {
          cart.appliedVouchers.length > 0 ? this.couponForm.get('couponCode')?.disable() : this.couponForm.get('couponCode')?.enable();
          this.cartId = activeCardId;
          this.getApplicableCustomerCoupons(
            cart,
            customerCoupons.coupons ?? []
          );
        }
      ),
      map(([cart]: [Cart, string, CustomerCouponSearchResult]) => cart)
    );

    this.cartIsLoading$ = this.activeCartService
      .isStable()
      .pipe(map((loaded) => !loaded));

    this.cartVoucherService.resetAddVoucherProcessingState();

    // TODO: (CXSPA-7479) Remove feature flags next major
    const shouldHaveRequiredValidator = !this.featureConfigService?.isEnabled(
      'a11yDisabledCouponAndQuickOrderActionButtonsInsteadOfRequiredFields'
    );

    this.couponForm = this.formBuilder.group({
      couponCode: [
        '',
        shouldHaveRequiredValidator ? [Validators.required] : [],
      ],
    });

    // TODO(#7241): Replace process subscriptions with event listeners and drop process for ADD_VOUCHER
    this.subscription.add(
      this.cartVoucherService
        .getAddVoucherResultSuccess()
        .subscribe((success) => {
          this.onSuccess(success);
        })
    );

    // TODO(#7241): Replace process subscriptions with event listeners and drop process for ADD_VOUCHER
    this.subscription.add(
      this.cartVoucherService.getAddVoucherResultError().subscribe((error) => {
        this.onError(error);
      })
    );
  }
}
