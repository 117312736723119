import { Injectable } from '@angular/core';
import { GlobalMessageService, ScriptLoader, ScriptPlacement, WindowRef } from '@spartacus/core';

@Injectable({
  providedIn: 'root',
})
export class OsborneCaptchaLoaderService {
  readonly scriptUrl = 'https://www.google.com/recaptcha/api.js';

  constructor(
    protected scriptLoader: ScriptLoader,
    private readonly winRef: WindowRef,
    protected globalMessageService: GlobalMessageService,
  ) {}

  loadScript(apiKey: string): void {
    if (apiKey && !this.hasScript()) {
      this.scriptLoader.embedScript({
        src: this.scriptUrl,
        params: { render: apiKey },
        errorCallback: () => this.handleLoadError(),
        placement: ScriptPlacement.HEAD,
      });
    }
  }

  protected hasScript(): boolean {
    if (this.winRef.isBrowser()) {
      return !!this.winRef.document.querySelector(`script[src^="${this.scriptUrl}"]`);
    }
    return false;
  }

  protected handleLoadError(): void {
    // Remove script to be able to load it again later
    if (this.winRef.isBrowser()) {
      const script = this.winRef.document.querySelector(`script[src^="${this.scriptUrl}"]`);
      script?.parentElement?.removeChild(script);
      console.error('Error loading bugherd script');
    }
  }
}
