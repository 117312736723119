import { ChangeDetectionStrategy, Component, HostBinding, inject } from '@angular/core';
import { BaseSiteService } from '@spartacus/core';
import { tap } from 'rxjs';

@Component({
  selector: 'osborne-captcha-disclaimer',
  templateUrl: './captcha-disclaimer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OsborneCaptchaDisclaimerComponent {
  @HostBinding('class') site: string;
  baseSiteService = inject(BaseSiteService);

  site$ = this.baseSiteService.getActive().pipe(
      tap(site => this.site = site)
    );
}
