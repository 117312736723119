import { AfterViewInit, Component, OnDestroy, OnInit, signal } from '@angular/core';
import { FormControl, FormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { AnonymousConsentsConfig, AnonymousConsentsService, Country, ProcessSelectors, RoutingService } from '@spartacus/core';
import { CustomFriendsService } from '../../../../../../spartacus/custom/core/custom-friends/custom-friends-users/facade/custom-friends.service';
import { find, tap } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { GUEST_FRIEND_FEATURE, StateWithGuest } from './store/custom-friends-guest.state';
import { select, Store } from '@ngrx/store';
import { StateWithProcess } from '@spartacus/core/src/process/store/process-state';
import { CustomUserAddressService } from 'src/app/spartacus/custom/core/user/facade/custom-user-address.service';
import { CustomValidators } from 'src/app/spartacus/custom/shared/utils/validators/custom-validators';
import { GuestFriendsActionTypes } from './store/actions/custom-friends-guest.action';
import { Actions, ofType } from '@ngrx/effects';

@Component({
  selector: 'app-custom-register-friend-form',
  templateUrl: './custom-register-friend-form.component.html',
  styleUrls: ['./custom-register-friend-form.component.scss'],
})
export class CustomRegisterFriendFormComponent implements AfterViewInit, OnInit, OnDestroy {

  countries$: Observable<Array<Country>>;
  friendSignUp: FormGroup;
  subscription: Subscription = new Subscription();
  disabled = signal(false);

  constructor(
    private routingService: RoutingService,
    protected userAddressService: CustomUserAddressService,
    protected friendsService: CustomFriendsService,
    protected router: RoutingService,
    protected guestStore: Store<StateWithGuest | StateWithProcess<void>>,
    protected anonymousConsentsService: AnonymousConsentsService,
    protected anonymousConsentsConfig: AnonymousConsentsConfig,
    protected actions: Actions,
  ) {
    this.subscription.add(
      this.getGuestSuccesstLoading().subscribe((guestCreatedSuccess) => {
        if (guestCreatedSuccess.success) {
          this.routingService.go({ cxRoute: 'home' });
          this.friendsService.clearGuestFriends();
        }
      })
    );

    this.subscription.add(
      this.actions
        .pipe(
          ofType(GuestFriendsActionTypes.CREATE_GUEST_FRIEND_SUCCESS, GuestFriendsActionTypes.CREATE_GUEST_FRIEND_FAIL),
          tap(() => {
            this.disabled.set(false);
          }),
        )
        .subscribe(),
    );

    this.friendSignUp = new FormGroup({
      firstName: new FormControl('', Validators.required),
      uid: new FormControl('', [Validators.required, Validators.maxLength(60), CustomValidators.emailValidator]),
      password: new FormControl('', [Validators.required, CustomValidators.passwordValidator]),
      confirmPassword: new FormControl('', [Validators.required, CustomValidators.passwordValidator]),
      exclusiveRole: new FormControl(''),
      supportedOnBehalfOfExclusiveRole: new FormControl(''),
      newsletter: new UntypedFormControl({
        value: true,
        disabled: this.overrideIsConsentRequired(),
      }),
    }, {
      validators: CustomValidators.passwordsMustMatch('password', 'confirmPassword'),
    });
  }

  getGuestSuccesstLoading(): Observable<any> {
    return this.guestStore.pipe(select(ProcessSelectors.getProcessStateFactory(GUEST_FRIEND_FEATURE)));
  }

  ngOnInit(): void {
    this.userAddressService.clearUserMiscData();
  }

  ngAfterViewInit(): void {
    this.friendsService.getGuestFriend();
    this.subscription.add(
      this.friendsService
        .getGuestSelectedFriends()
        .pipe(find((value) => !!value))
        .subscribe((friend: any) => {
          this.friendSignUp.patchValue(friend);
          const registerConsent = this.anonymousConsentsConfig?.anonymousConsents?.registerConsent;
          this.anonymousConsentsService.giveConsent(registerConsent);
        })
      );
  }

  saveGuestFriend(): void {
    if (this.friendSignUp.valid) {
      this.disabled.set(true);
      this.friendsService.createGuestFriend({
        ...this.friendSignUp.value
      });
    }else {
      this.friendSignUp.markAllAsTouched();
    }
  }

  overrideIsConsentRequired(): boolean {
    this.anonymousConsentsService.loadTemplates();

    const requiredConsents = this.anonymousConsentsConfig?.anonymousConsents?.requiredConsents;
    const registerConsent = this.anonymousConsentsConfig?.anonymousConsents?.registerConsent;

    if (requiredConsents && registerConsent) {
      return requiredConsents.includes(registerConsent);
    }

    return false;
  }

  ngOnDestroy(): void {
      this.subscription.unsubscribe();
  }
}
