<h2>{{
  'loginForm.access' | cxTranslate
  }}</h2>

<form (ngSubmit)="saveGuestFriend()" [formGroup]="friendSignUp">
  <div class="form-group">
    <label>
      <span class="label-content required">{{ 'myFriends.myFriendsForm.name' | cxTranslate }}</span>
      <input class="form-control" type="text" formControlName="firstName" />
      <cx-form-errors [control]="friendSignUp.get('firstName')"></cx-form-errors>
    </label>
  </div>

  <div class="form-group">
    <label>
      <span class="label-content required">{{ 'myFriends.myFriendsForm.email' | cxTranslate }}</span>
      <input
        [maxLength]="60"
        class="form-control"
        readonly type="email"
        formControlName="uid"
      />
      <cx-form-errors [control]="friendSignUp.get('uid')"></cx-form-errors>
    </label>
  </div>

  <div class="form-group">
    <label>
      <span class="label-content required">{{ 'myFriends.myFriendsForm.password' | cxTranslate }}</span>
      <input class="form-control" type="password" formControlName="password" />
      <cx-form-errors [control]="friendSignUp.get('password')"></cx-form-errors>
    </label>
  </div>
  <div class="form-group">
    <label>
      <span class="label-content required">{{ 'myFriends.myFriendsForm.confirmPassword' | cxTranslate }}</span>
      <input class="form-control" type="password" formControlName="confirmPassword" />
      <cx-form-errors [control]="friendSignUp.get('confirmPassword')"></cx-form-errors>
    </label>
  </div>

  <!-- BUTTON SECTION -->
  <button type="submit" class="btn btn-block btn-login" [disabled]="disabled()">
    {{ 'loginForm.signIn' | cxTranslate }}
  </button>
</form>
