import { registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import {BrowserModule, BrowserTransferStateModule} from '@angular/platform-browser';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { PageLayoutModule, PageSlotModule } from '@spartacus/storefront';
import { AppRoutingModule } from '@spartacus/storefront';
import { AppComponent } from './app.component';
import { CustomModule } from 'src/app/custom/custom-module';
import { SpartacusModule } from './spartacus/spartacus.module';
import localeEs from '@angular/common/locales/es';
import { environment } from '../environments/environment';
import { RecaptchaModule } from '../../../../src/app/spartacus/features/recaptcha/recaptcha.module';
import { CustomLocalizedRoutingModule } from 'src/app/custom/custom-localized-routing/custom-localized-routing.module';

registerLocaleData(localeEs);

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverRiofrioApp' }),
    HttpClientModule,
    CustomLocalizedRoutingModule,
    AppRoutingModule,
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
    SpartacusModule,
    CustomModule,
    PageLayoutModule,
    PageSlotModule,
    StoreDevtoolsModule.instrument({
      maxAge: 50,
      logOnly: !environment.production,
    }),
    BrowserTransferStateModule,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
