import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { I18nModule, provideConfig } from '@spartacus/core';
import { CaptchaApiConfig } from '@spartacus/storefront';
import { RecaptchaModule, RecaptchaV3Module } from 'ng-recaptcha';
import { OsborneCaptchaDisclaimerComponent } from './captcha-disclaimer.component';
import { OsborneCaptchaService } from './captcha.service';

@NgModule({
  imports: [CommonModule, RecaptchaModule, RecaptchaV3Module, I18nModule],
  declarations: [OsborneCaptchaDisclaimerComponent],
  exports: [OsborneCaptchaDisclaimerComponent],
  providers: [
    provideConfig(<CaptchaApiConfig>{
      captchaRenderer: OsborneCaptchaService,
    }),
  ],
})
export class OsborneCaptchaModule {}
