import { NgModule } from '@angular/core';
import { CustomEmptyCartActionsModule } from './cms-components/cart/custom-empty-cart-actions/custom-empty-cart-actions.module';
import { CustomCategorySummaryModule } from './cms-components/content/custom-category-summary/custom-category-summary.module';
import { CustomDoubleImageInfoModule } from './cms-components/content/custom-double-image-info/custom-double-image-info.module';
import { CustomImageInfoModule } from './cms-components/content/custom-image-info/custom-image-info.module';
import { CustomPageTopTitleModule } from './cms-components/content/custom-page-top-title/custom-page-top-title.module';
import { CustomVideoInfoModule } from './cms-components/content/custom-video-info/custom-video-info.module';
import { CustomGiftboxCartTotalsModule } from './cms-components/giftbox/custom-giftbox-cart-totals/custom-giftbox-cart-totals.module';
import { CustomGiftboxCartModule } from './cms-components/giftbox/custom-giftbox-cart/custom-giftbox-cart.module';
import { CustomGiftboxProductHeaderModule } from './cms-components/giftbox/custom-giftbox-product-header/custom-giftbox-product-header.module';
import { CustomBillHistoryModule } from './cms-components/myaccount/custom-bills/custom-bill-history/custom-bill-history.module';
import { CustomOrderTicketDownloadModule } from './cms-components/myaccount/custom-order-ticket-download/custom-order-ticket-download.module';
import { CustomMyAccountFriendEditFormModule } from './cms-components/myaccount/custom-friends/custom-friends.module';
import { CustomStatisticsModule } from './cms-components/myaccount/custom-statistics/custom-statistics.module';
import { CustomVideoCarouselModule } from './cms-components/content/custom-video-carousel/custom-video-carousel.module';
import { CustomNewslettersModule } from './cms-components/content/custom-newsletters/custom-newsletters.module';
import { CustomVideoFullWidthModule } from './cms-components/content/custom-video-full-width/custom-video-full-width.module';
import { CustomMiniCartPreviewModule } from './cms-components/cart/custom-mini-cart-preview/custom-mini-cart-preview.module';
import { CustomNewsModule } from './cms-components/content/custom-news/custom-news.module';
import { CustomCarouselBannerSliderModule } from './cms-components/content/custom-carousel-banner-slider/custom-carousel-banner-slider.module';
import { CustomCarouselModule } from '../spartacus/custom/cms-structure/shared/components/custom-carousel/custom-carousel.module';
import { CustomBannerAndProductsGridModule } from './cms-components/content/custom-banner-and-products-grid/custom-banner-and-products-grid.module';
import { CustomParagraphColumnWithExpandableInfoModule } from './cms-components/content/custom-paragraph-column-with-expandable-info/custom-paragraph-column-with-expandable-info.module';
import { CustomParagraphColumnInfoModule } from './cms-components/content/custom-paragraph-column-info/custom-paragraph-column-info.module';
import { CustomParagraphModule } from '../spartacus/custom/cms-components/content/custom-paragraph/custom-paragraph.module';
import { HtmlSanitizeModule } from './util/html-sanitize/html-sanitize.module';
import { HtmlSanitizePipe } from './util/html-sanitize/html-sanitize.pipe';
import { CustomMediaLinksModule } from './cms-components/content/custom-media-links/custom-media-links.module';
import { CustomSocialMediaShareModule } from './cms-components/content/custom-social-media-share/custom-social-media-share.module';
import { CustomColumnsWithFilesContainerModule } from './cms-components/content/custom-columns-with-files-container/custom-columns-with-files-container.module';
import { CustomPaypalButtonModule } from './cms-components/checkout/custom-paypal-button/custom-paypal-button.module';
import { CustomContentPagesSliderBannerModule } from './cms-components/content/custom-content-pages-slider-banner/custom-content-pages-slider-banner.module';
import { CustomContactFormModule } from './cms-components/atc/custom-contact-form/custom-contact-form.module';
import { CustomHoverAwareImagesContainerModule } from './cms-components/content/custom-hover-aware-images-container/custom-hover-aware-images-container.module';
import { CustomRestoreCartModule } from './cms-components/cart/custom-restore-cart/custom-restore-cart.module';
import { CustomImageInfoContainerModule } from './cms-components/content/custom-image-info-container/custom-image-info-container.module';
import { CustomDividerModule } from './cms-components/content/custom-divider/custom-divider.module';
import { CustomProductCategoriesModule } from './cms-components/product/custom-product-categories/custom-product-categories.module';
import {CustomPaypalInstallmentsModule} from "./cms-components/paypal/custom-paypal-installments/custom-paypal-installments.module";
import {CustomProductLabelsModule} from "./cms-components/product/custom-product-labels/custom-product-labels.module";


@NgModule({
  declarations: [],
  imports: [
    HtmlSanitizeModule,
    CustomPageTopTitleModule,
    CustomCategorySummaryModule,
    CustomMyAccountFriendEditFormModule,
    CustomBillHistoryModule,
    CustomOrderTicketDownloadModule,
    CustomStatisticsModule,
    CustomEmptyCartActionsModule,
    CustomVideoInfoModule,
    CustomImageInfoModule,
    CustomDoubleImageInfoModule,
    CustomVideoCarouselModule,
    CustomGiftboxCartModule,
    CustomGiftboxProductHeaderModule,
    CustomGiftboxCartTotalsModule,
    CustomNewslettersModule,
    CustomVideoFullWidthModule,
    CustomMiniCartPreviewModule,
    CustomNewsModule,
    CustomCarouselBannerSliderModule,
    CustomCarouselModule,
    CustomBannerAndProductsGridModule,
    CustomParagraphColumnInfoModule,
    CustomParagraphColumnWithExpandableInfoModule,
    CustomParagraphModule,
    CustomMediaLinksModule,
    CustomSocialMediaShareModule,
    CustomColumnsWithFilesContainerModule,
    CustomPaypalButtonModule,
    CustomContentPagesSliderBannerModule,
    CustomContactFormModule,
    CustomHoverAwareImagesContainerModule,
    CustomRestoreCartModule,
    CustomImageInfoContainerModule,
    CustomDividerModule,
    CustomProductCategoriesModule,
    CustomPaypalInstallmentsModule,
    CustomProductLabelsModule,
  ],
  providers: [HtmlSanitizePipe]
})
export class CustomModule {}
