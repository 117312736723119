import { NgModule } from '@angular/core';
import { CartConfig } from '@spartacus/cart/base/root';
import { CheckoutConfig, CheckoutStepType } from '@spartacus/checkout/base/root';
import {
  provideConfig,
  AuthConfig,
  RoutingConfig,
  provideDefaultConfig,
} from '@spartacus/core';
import {
  defaultCmsContentProviders,
} from '@spartacus/storefront';
import { CustomBaseModule } from 'src/app/custom/cms-components/header/base-store/custom-base-store.module';
import { CustomExtraAppConfig } from 'src/app/custom/config/custom-extra-app-config';
import { CustomCreditCardModule } from 'src/app/spartacus/custom/cms-components/checkout/components/payment-method/custom-credit-card/custom-credit-card.module';
import { CustomFriendsUsersModule } from 'src/app/spartacus/custom/core/custom-friends/custom-friends-users/custom-friends-users.module';
import { CustomFriendsModule } from 'src/app/spartacus/custom/core/custom-friends/custom-friends.module';
import { RecaptchaVersion } from 'src/app/spartacus/features/recaptcha/recaptcha.model';
import {
  customRoutes,
  customSharedSpartacusConfigurationConfig,
  customSharedSpartacusConfigurationEffects,
  customSharedSpartacusConfigurationLayoutConfig,
  customSharedSpartacusConfigurationLayoutMediaConfig,
  customSharedSpartacusConfigurationProviders
} from 'src/app/spartacus/spartacus-configuration.module';

export const customCheckoutConfig: CheckoutConfig = {
  checkout: {
    steps: [
      {
        id: 'deliveryAddress',
        name: 'checkoutProgress.shippingAddress',
        routeName: 'checkoutDeliveryAddress',
        type: [CheckoutStepType.DELIVERY_ADDRESS],
      },
      {
        id: 'deliveryMode',
        name: 'checkoutProgress.deliveryMode',
        routeName: 'checkoutDeliveryMode',
        type: [CheckoutStepType.DELIVERY_MODE],
      },
      {
        id: 'paymentDetails',
        name: 'checkoutProgress.methodOfPayment',
        routeName: 'checkoutPaymentDetails',
        type: [CheckoutStepType.PAYMENT_DETAILS, CheckoutStepType.REVIEW_ORDER],
      }
    ],
    guest: true,
  }
};

@NgModule({
  declarations: [],
  imports: [
    customSharedSpartacusConfigurationEffects,
    CustomFriendsModule,
    CustomFriendsUsersModule,
    CustomBaseModule,
    CustomCreditCardModule,
  ],
  providers: [
    customSharedSpartacusConfigurationLayoutConfig,
    customSharedSpartacusConfigurationLayoutMediaConfig,
    ...defaultCmsContentProviders,
    provideConfig(<RoutingConfig>{
      routing: {
        protected: false,
        routes: {
          ...customRoutes,
        }
      }
    }),
    provideConfig({
      customExtraApp: {
        enabledCustomerRegister: true,
        allowFriends: false,
        checkoutBilling: true,
        extendedLoginInfo: true,
        miniCartPreview: true,
        showAgePopup: false,
        reCaptcha: {
          enabled: true,
          version: RecaptchaVersion.V2,
        },
      }
    } as CustomExtraAppConfig),
    provideConfig({
      authentication: {
        client_id: 'riofrio',
        client_secret: 'secret',
        tokenEndpoint: '/oauth/token',
        revokeEndpoint: '/oauth/revoke',
        loginUrl: '/oauth/authorize',
        OAuthLibConfig: {
          scope: '',
          customTokenParameters: ['token_type'],
          strictDiscoveryDocumentValidation: false,
          skipIssuerCheck: true,
          disablePKCE: true,
          oidc: false,
          clearHashAfterLogin: false,
        },
      },
    } as AuthConfig),
    provideConfig({
      ...customSharedSpartacusConfigurationConfig,
      context: {
        currency: ['EUR', 'USD'],
        language: ['es', 'en'],
        urlParameters: ['language'],
      },
    }),
    provideConfig(customCheckoutConfig),
    ...customSharedSpartacusConfigurationProviders,
    provideDefaultConfig(<CartConfig> {
      cart: {
        selectiveCart: {
          enabled: false,
        }
      }
    })
  ],
})
export class SpartacusConfigurationModule {
}
