import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, UntypedFormGroup } from '@angular/forms';
import { CustomPreferenceCenterService } from '../core/facade/custom-preference-center.service';
import { Observable, Subscription, combineLatest } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { EmarsysContactInfo, mainStoreMap, storeMap, emarsysProvinces, emarsysLanguages, languageGroups } from '../../../../model/preference-center.model';
import { ICON_TYPE } from '@spartacus/storefront';
import { CxDatePipe, Language, Region, RoutingService, TranslationService } from '@spartacus/core';
import { ActivatedRoute } from '@angular/router';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-custom-preference-center',
  templateUrl: './custom-preference-center-form.component.html',
})
export class CustomPreferenceCenterFormComponent implements OnInit, OnDestroy {
  iconTypes = ICON_TYPE;
  form: FormGroup = this.preferenceCenterService.form;
  isUpdating$: Observable<boolean> = this.preferenceCenterService.isUpdating$;
  data$: Observable<EmarsysContactInfo>;
  provinces: Region[] = emarsysProvinces;
  languages: Language[] = [];
  routerData;
  birthDate: Date | undefined;
  storeId: string | undefined;
  private subscriptions = new Subscription();

  constructor(
    protected preferenceCenterService: CustomPreferenceCenterService,
    protected routingService: RoutingService,
    protected router: ActivatedRoute,
    protected translationService: TranslationService,
    private cxDatePipe: CxDatePipe,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.routingService.getRouterState().pipe(
        tap((data) => {
          this.routerData = data;

          this.storeId = mainStoreMap.get(this.document.location.host);
          if(this.storeId === '3920') {
            this.storeId = storeMap.get(data.state.context.id);
          }
          this.data$ = this.getPreferenceDetails(data.state.queryParams.uid, this.storeId);
        }),
        switchMap(() => {
          const availableLanguages = this.getStoreSpecificLanguages(this.storeId);
          return combineLatest(
            availableLanguages.map((language) => {
              return this.translationService.translate(language.name);
            })
          ).pipe(
            tap((translations) => {
              this.languages = availableLanguages.map((language, i) => {
                return { name: translations[i], isocode: language.isocode };
              });
            })
          );
        }),
      ).subscribe(),
    );

    this.subscriptions.add(
      this.form.valueChanges.pipe(
        tap((data) => {
          this.checkNumericField('phoneNumber', this.form, data);
        }),
      ).subscribe(),
    );
  }

  getStoreSpecificLanguages(storeId: string): Language[] {
    const languages = languageGroups.find(group => group.stores.includes(storeId));

    if(languages) {
      return emarsysLanguages.filter(lang => languages.isocode.includes(lang.isocode));
    }
    return [];
  }
  
  checkNumericField(field: string, form: UntypedFormGroup | FormGroup, data: { [key: string]: string }) {
    if (data && data[field] && !this.isNumericOnly(data[field])) {
      form.get(field)?.setValue(data[field].replace(/[^0-9.]/g, ''));
    }
  }

  isNumericOnly(value: string): boolean {
    return /^[0-9]*$/.test(value);
  }

  getPreferenceDetails(uid: string, storeId: string): Observable<EmarsysContactInfo> {
    return this.preferenceCenterService.loadPreferenceCenterDetails(uid, storeId).pipe(
      tap((data: EmarsysContactInfo) => {
        if (data) {
          this.birthDate = new Date(data.birthDate);

          this.form.patchValue({
            name: data.name,
            surnames: data.surnames,
            email: data.email,
            birthDate: data.birthDate,
            province: data.province,
            phoneNumber: data.phoneNumber,
            language: data.language,
            subscribed: data.subscribed,
          });
        }
      })
    )
  }

  onSubmit(): void {
    const birthDate = this.form.get('birthDate').value ? this.form.get('birthDate').value.split('/').reverse().join('-') : '';

    const details : EmarsysContactInfo = {
      name: this.form.get('name').value,
      surnames: this.form.get('surnames').value,
      email: this.form.get('email').value,
      birthDate: birthDate,
      province: this.form.get('province').value || '',
      phoneNumber: this.form.get('phoneNumber').value ? `+${this.form.get('phoneNumber').value}` : '',
      language: this.form.get('language').value,
      subscribed: this.form.get('subscribed').value,
      store: this.storeId,
      timestamp: '',
    }

    const { uid, cid, llid } = this.routerData.state.queryParams;

    this.preferenceCenterService.updatePreferenceCenterDetails(uid, cid, llid, details);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
