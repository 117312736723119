<ng-container *ngIf="cart$ | async as cart">
  <div class="form-group">
    <!-- TODO: (CXSPA-5953) Remove feature flags next major -->
    <ng-container
      *cxFeature="
        '!a11yDisabledCouponAndQuickOrderActionButtonsInsteadOfRequiredFields'
      "
    >
      <p *cxFeature="'a11yRequiredAsterisks'" class="form-legend">
        {{ 'formLegend.required' | cxTranslate }}
      </p>
    </ng-container>

    <form (ngSubmit)="applyVoucher()" [formGroup]="couponForm">
      <label id="card-coupon-input-label" class="cx-cart-coupon-title">
        {{ 'voucher.addCoupon' | cxTranslate }}
        <!-- TODO: (CXSPA-7479) Remove feature flags next major -->
        <ng-container
          *cxFeature="
            '!a11yDisabledCouponAndQuickOrderActionButtonsInsteadOfRequiredFields'
          "
        >
          <abbr
            *cxFeature="'a11yRequiredAsterisks'"
            aria-hidden="true"
            class="text-decoration-none"
            title="{{ 'common.required' | cxTranslate }}"
            >*</abbr
          >
        </ng-container>
      </label>

      <div class="cx-cart-coupon-container">
        <!-- TODO: (CXSPA-7479) Remove feature flags next major -->
        <ng-container
          *cxFeature="
            'a11yDisabledCouponAndQuickOrderActionButtonsInsteadOfRequiredFields'
          "
        >
          <input
            [attr.aria-labelledby]="'card-coupon-input-label'"
            type="text"
            class="form-control input-coupon-code"
            formControlName="couponCode"
            placeholder="{{ 'voucher.placeholder' | cxTranslate }}"
          />
          <button
            class="btn btn-block btn-secondary apply-coupon-button"
            type="submit"
            [disabled]="
              (cartIsLoading$ | async) || !couponForm?.get('couponCode')?.value || cart.appliedVouchers.length > 0
            "
            [class.disabled]="(cartIsLoading$ | async) || cart.appliedVouchers.length > 0"
          >
            {{ 'voucher.apply' | cxTranslate }}
          </button>
        </ng-container>

        <ng-container
          *cxFeature="
            '!a11yDisabledCouponAndQuickOrderActionButtonsInsteadOfRequiredFields'
          "
        >
          <!-- TODO: (CXSPA-7479) Remove feature flags next major -->
          <input
            [attr.aria-labelledby]="'card-coupon-input-label'"
            required="true"
            type="text"
            class="form-control input-coupon-code"
            formControlName="couponCode"
            placeholder="{{ 'voucher.placeholder' | cxTranslate }}"
          />
          <button
            class="btn btn-block btn-secondary apply-coupon-button"
            type="submit"
            [disabled]="(cartIsLoading$ | async) || cart.appliedVouchers.length > 0"
            [class.disabled]="(cartIsLoading$ | async) || cart.appliedVouchers.length > 0"
          >
            {{ 'voucher.apply' | cxTranslate }}
          </button>
          <!-- TODO: (CXSPA-7315) Remove feature toggle in the next major -->
          <cx-form-errors
            *cxFeature="'formErrorsDescriptiveMessages'"
            [translationParams]="{
              label: 'voucher.placeholder' | cxTranslate,
            }"
            [control]="couponForm.get('couponCode')"
          ></cx-form-errors>

          <cx-form-errors
            *cxFeature="'!formErrorsDescriptiveMessages'"
            [control]="couponForm.get('couponCode')"
          ></cx-form-errors>
        </ng-container>
      </div>
    </form>
  </div>

  <cx-applied-coupons
    [vouchers]="cart.appliedVouchers"
    [cartIsLoading]="cartIsLoading$ | async"
    [isReadOnly]="false"
  >
  </cx-applied-coupons>

  <ng-container *ngIf="applicableCoupons && applicableCoupons.length > 0">
    <div class="cx-available-coupon">
      <div class="title cx-cart-coupon-title">
        {{ 'voucher.availableCoupons' | cxTranslate }}
      </div>
      <div class="message">
        {{ 'voucher.availableCouponsLabel' | cxTranslate }}
      </div>
      <div class="scroll">
        <div class="coupons card" *ngFor="let coupon of applicableCoupons">
          <button
            (click)="applyCustomerCoupon(coupon.couponId)"
            class="coupon-id link"
            [disabled]="cartIsLoading$ | async"
            [class.disabled]="cartIsLoading$ | async"
          >
            {{ coupon.couponId }}
          </button>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
