import { AfterContentChecked, ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';
import { Product, TranslationService, WindowRef } from '@spartacus/core';
import { ProductDetailOutlets } from '@spartacus/storefront';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CustomCurrentProductService } from "../../../core/product/services/custom-current-product.service";
import { CustomProductDetailsEventService } from 'src/app/spartacus/features/tracking/custom-events/product/custom-product-details-event.service';

@Component({
  selector: 'app-custom-product-intro',
  templateUrl: './custom-product-intro.component.html',
})
export class CustomProductIntroComponent implements AfterContentChecked {
  @HostBinding('class') styleClasses: string;
  outlets = ProductDetailOutlets;

  reviewsTabAvailable = new BehaviorSubject<boolean>(false);

  product$: Observable<Product> = this.currentProductService.getProduct()
    .pipe(
      tap((product) => {
        this.styleClasses = product.stock && product.stock.stockLevelStatus !== 'outOfStock' ? 'has-stock' : '';
        this.customProductDetailsEventService.dispatchProductDetailSelectEvent(product, 'productPage');
      })
    );

  constructor(
    protected currentProductService: CustomCurrentProductService,
    private translationService: TranslationService,
    protected winRef: WindowRef,
    protected customProductDetailsEventService?: CustomProductDetailsEventService,
  ) {}

  ngAfterContentChecked(): void {
    const productTitle = this.winRef?.document?.getElementById('product-title');
    const titleHeight = productTitle?.offsetHeight > 20 ? (productTitle.offsetHeight + 6) + 'px' : '31px';
    this.winRef?.document?.body?.style?.setProperty('--cx-pdp-title-height', titleHeight);
  }

  // Scroll to views component on page and click "Reviews" tab
  showReviews(): void {
    // Use translated label for Reviews tab reference
    this.translationService
      .translate('TabPanelContainer.tabs.ProductReviewsTabComponent')
      .subscribe((reviewsTabLabel) => {
        const tabsComponent = this.getTabsComponent();
        const reviewsTab = this.getTabByLabel(reviewsTabLabel, tabsComponent);
        const reviewsComponent = this.getReviewsComponent();
        if (reviewsTab && reviewsComponent) {
          this.clickTabIfInactive(reviewsTab);
          setTimeout(
            () => reviewsComponent.scrollIntoView({ behavior: 'smooth' }),
            0
          );
        }
      })
      .unsubscribe();
  }

  // NOTE: Does not currently exists as its own component
  // but part of tabs component. This is likely to change in refactor.
  private getReviewsComponent(): Element {
    return this.winRef.document.querySelector('cx-product-reviews');
  }

  // Get Tabs Component if exists on page
  private getTabsComponent(): Element {
    return this.winRef.document.querySelector('app-custom-tab-paragraph-container');
  }

  // Click to activate tab if not already active
  private clickTabIfInactive(tab: HTMLElement): void {
    if (
      !tab.classList.contains('active') ||
      tab.classList.contains('toggled')
    ) {
      tab.click();
    }
  }

  // Get Tab by label if exists on page
  private getTabByLabel(label: string, tabsComponent: Element): HTMLElement {
    if (tabsComponent) {
      // NOTE: Reads through button tags to click on correct tab
      // There may be a better way of doing this now/after refactor
      const tabElements: HTMLCollectionOf<HTMLElement> = tabsComponent.getElementsByTagName(
        'button'
      );

      // Look through button tab elements until finding tab with label
      for (const buttonElement of Array.from(tabElements)) {
        if (buttonElement.innerHTML.includes(label)) {
          return buttonElement;
        }
      }
    }
  }
}
