import { Component, ComponentRef, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  Address,
  Country,
  EventService, GlobalMessageService, GlobalMessageType, Region,
  RoutingService,
  TranslationService,
  UserCostCenterService,
  UserIdService,
  getLastValueSync,
  isNotUndefined
} from '@spartacus/core';
import {
  Card,
  ICON_TYPE,
  LaunchDialogService
} from '@spartacus/storefront';
import { ChangeDetectorRef } from '@angular/core';
import { User, UserAccountFacade } from '@spartacus/user/account/root';
import { combineLatest, Observable, of, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, startWith, switchMap, take, tap, timeoutWith, withLatestFrom } from 'rxjs/operators';
import { CustomExtraAppConfigService } from 'src/app/custom/config/services/custom-extra-app-config.service';
import { CustomValidateCartService } from 'src/app/services/cart/validate-cart.service';
import { CustomActiveCartService } from 'src/app/spartacus/custom/core/cart/facade/custom-active-cart.service';
import { CustomCheckoutPaymentService } from 'src/app/spartacus/custom/core/checkout/facade/custom-checkout-payment.service';
import { CustomUserAddressService } from 'src/app/spartacus/custom/core/user/facade/custom-user-address.service';
import { CustomValidators } from 'src/app/spartacus/custom/shared/utils/validators/custom-validators';
import { CustomLoadCartFailEvent, CustomLoadCartSuccessEvent, CustomSetDocumentIdentifierSuccessEvent } from 'src/app/spartacus/features/tracking/custom-events/cart/custom-cart.events';
import { CUSTOM_LAUNCH_CALLER } from 'src/app/spartacus/spartacus-custom.module';
import { BaseStoreModel } from '../../../../../../custom/cms-components/header/base-store/custom-base-store.model';
import { CustomPopupShippingService } from '../../../../../../custom/cms-components/user/popup-shipping/custom-popup-shipping.service';
import { filterText } from '../../../../../../custom/util/filter-text';
import { CustomMultiCartService } from '../../../../core/cart/facade/custom-multi-cart.service';
import { CustomCheckoutDeliveryService } from '../../../../core/checkout/facade/custom-checkout-delivery.service';
import { CustomFriendsCheckoutModel } from '../../../../core/custom-friends/custom-friends-checkout/facade/custom-friends-checkout.model';
import { CustomFriendsCheckoutService } from '../../../../core/custom-friends/custom-friends-checkout/facade/custom-friends-checkout.service';
import { Cart, CartEvent } from '@spartacus/cart/base/root';
import { CheckoutCostCenterFacade } from '@spartacus/checkout/b2b/root';
import { CheckoutDeliveryAddressComponent, CheckoutStepService, CardWithAddress } from '@spartacus/checkout/base/components';
import { CheckoutConfig, CheckoutDeliveryModesFacade } from '@spartacus/checkout/base/root';
import { CustomCheckoutPaymentTypeService } from 'src/app/spartacus/custom/core/checkout/facade/custom-checkout-payment-type.service';

@Component({
  selector: 'app-custom-shipping-address',
  templateUrl: './custom-shipping-address.component.html',
  styleUrls: ['./custom-shipping-address.component.scss'],
})

export class CustomShippingAddressComponent extends CheckoutDeliveryAddressComponent {

  iconTypes = ICON_TYPE;
  selectedFriendFromCart$: Observable<CustomFriendsCheckoutModel>;
  deliveryAddressFromCart$: Observable<any>;
  billingAddressFromCart$: Observable<Address>;
  deliveryAddressArray$: Observable<Address[]>;
  selectedFriend$: Observable<CustomFriendsCheckoutModel>;
  friendsList$: Observable<CustomFriendsCheckoutModel[]>;
  onBehalfOfTypes$: Observable<any>;
  currentBaseStore: BaseStoreModel;

  showFriendList = false;
  showAddressList = false;
  showFriendOnly = false;
  verifiedFriend = false;
  disableNextButton = false;

  friendsList: CustomFriendsCheckoutModel[];
  selectedFriend: CustomFriendsCheckoutModel;
  defaultDeliveryAddress: Address;
  defaultBillingAddress: Address;
  onBehalfForm: FormGroup;
  addressList: Address[] = [];
  onBehalfOfAddress: Address[] = [];
  totalCards = 0;
  isPickupStore = false;
  filteredAddresses$: Observable<Address[]>;
  filter: FormControl;
  filter$: Observable<string>;
  allowFriends: boolean;
  handleBilling: boolean;
  billingAddressForm: FormGroup;
  documentIdentifierForm: FormGroup;
  requestBilling: boolean;
  countries$: Observable<Country[]>;
  regions$: Observable<Region[]>;
  sameBillingAsShippingAddress = true;
  billingAddressLoaded = false;
  user$: Observable<User>;
  isStableCart$ = this.activeCartService.isStable();
  isValidatingCart$ = this.customValidateCartService.getValidateCartLoading();
  isSimulatingCart$ = this.customValidateCartService.getSimulateCartLoading();
  isAccountPayment = false;
  showMyInformation = false
  showMyInformationEn = false
  cart?: Cart;

  protected subscriptions = new Subscription();

  selectedAddress: Address;
  isNifValid :  boolean = true;
  forceLoader: boolean;

  countryIsocodes$: Observable<string[]> = this.userAddressService.getDeliveryCountries()
  .pipe(filter(isNotUndefined),map(countries => countries.map(item => item.isocode)));
  customTableData$: Observable<Address[]> = combineLatest([this.tableData$, this.countryIsocodes$]).pipe(
    // if no data is available for 5 seconds, return an empty array
    timeoutWith(5000, of([] as Address[])),
    switchMap((data:[Address[],string[]]) => {
      const [tableData, isocodes] = data;
      if (!tableData || !(tableData instanceof Array)) return of([] as Address[]);
      const filteredData = isocodes.length ? tableData.filter(tableRow => isocodes.includes(tableRow.country.isocode)) : tableData;
      return of(filteredData) as Observable<Address[]>;
    })
  );


  customSelectedAddress$: Observable<Address[]|null> = this.selectedAddress$.pipe(
    switchMap((selectedAddress) => {
      if (selectedAddress) {
        return of([selectedAddress]);
      }
      return of(null);
    })
  );

  constructor(
    protected userAddressService: CustomUserAddressService,
    protected checkoutDeliveryAddressFacade: CustomCheckoutDeliveryService,
    protected activatedRoute: ActivatedRoute,
    protected translationService: TranslationService,
    protected activeCartService: CustomActiveCartService,
    protected checkoutStepService: CheckoutStepService,
    protected checkoutDeliveryModesFacade: CheckoutDeliveryModesFacade,
    protected globalMessageService: GlobalMessageService,
    protected routingService: RoutingService,
    private cdRef: ChangeDetectorRef,

    // custom
    protected customExtraAppConfigService: CustomExtraAppConfigService,
    protected paymentTypeService?: CustomCheckoutPaymentTypeService,
    protected userCostCenterService?: UserCostCenterService,
    protected checkoutCostCenterService?: CheckoutCostCenterFacade,
    protected checkoutService?: CustomCheckoutPaymentService,
    protected fb?: FormBuilder,
    protected store?: Store,
    protected userIdService?: UserIdService,
    protected customFriendsCheckoutService?: CustomFriendsCheckoutService,
    protected router?: Router,
    protected customValidateCartService?: CustomValidateCartService,
    protected launchDialogService?: LaunchDialogService,
    protected vcr?: ViewContainerRef,
    protected customPopupShippingService?: CustomPopupShippingService,
    protected multiCartService?: CustomMultiCartService,
    protected customCheckoutPaymentService?: CustomCheckoutPaymentService,
    protected eventService?: EventService,
    protected userAccountFacade?: UserAccountFacade,
    protected checkoutConfig?: CheckoutConfig,
  ) {
    super(
      userAddressService,
      checkoutDeliveryAddressFacade,
      activatedRoute,
      translationService,
      activeCartService,
      checkoutStepService,
      checkoutDeliveryModesFacade,
      globalMessageService
    );
    this.allowFriends = customExtraAppConfigService.allowFriends();
    this.handleBilling = customExtraAppConfigService.checkoutBilling();
    this.filter = new FormControl('');
    this.filter$ = this.filter.valueChanges.pipe(startWith(''));

    this.subscriptions.add(
      this.customPopupShippingService.getSelectedBaseStore().subscribe(
        currentBaseStore => {
          this.isPickupStore = currentBaseStore?.pickupStore;
          this.currentBaseStore = currentBaseStore;
        },
      ),
    );


    this.deliveryAddressFromCart$ = this.customFriendsCheckoutService.getDefaultAddressFromCart();

    this.onBehalfForm = this.fb.group({
      onBehalfOf: ['me', [Validators.required]],
      friend: [null],
    });

    if (this.handleBilling) {
      this.documentIdentifierForm = this.fb.group({
        name: ['', Validators.required],
        defaultAddress: this.fb.group({
          country: this.fb.group({
            isocode: ['', Validators.required],
          }),
        }),
        exclusiveRole: [''],
        documentIdentifier: ['', [
          Validators.required,
          CustomValidators.documentIdentifier('exclusiveRole', 'defaultAddress.country.isocode'),

        ]],
      });
      this.user$ = this.activeCartService.getAssignedUser();
      this.billingAddressFromCart$ = this.activeCartService.getBillingAddressFromCart();
      this.billingAddressForm = this.fb.group({
        firstName: ['', Validators.required],
        lastName: ['', Validators.required],
        line1: ['', [Validators.required, Validators.maxLength(35)]],
        line2: ['', Validators.maxLength(35)],
        town: ['', [Validators.required, Validators.maxLength(25)]],
        country: this.fb.group({
          isocode: [null, Validators.required],
        }),
        region: this.fb.group({
          isocode: [null, Validators.required],
        }),
        postalCode: ['', [
          Validators.required,
          Validators.maxLength(12),
          CustomValidators.postalCode('country.isocode'),
        ]],
        phone: ['', [
          Validators.required,
          CustomValidators.phoneValidator,
          CustomValidators.numberValidator,
        ]],
        email: ['', [
          Validators.required,
          Validators.maxLength(60),
          CustomValidators.emailValidator,
        ]],
      });

      this.subscriptions.add(
        this.billingAddressFromCart$
          .subscribe((billingAddress) => {
            if (billingAddress) {
              if (!this.requestBilling) {
                this.requestBilling = true;
              }
              this.billingAddressLoaded = true;
              let deliveryAddress: Address;
              /*
                (MLM), from: Same address if billing and shipping have the same ID
                         to: Same address if billing and shipping contain same String attributes
              */
              if (this.sameAddressAttributes(deliveryAddress, billingAddress)) {
                this.sameBillingAsShippingAddress = true;
              } else {
                this.sameBillingAsShippingAddress = false;
              }
            }
          })
      );

      /**
       * Subscription to billing address creation
       *
       */
      this.subscriptions.add(
        this.activeCartService.getCreateBillingAddressSuccess()
          .subscribe((createBillingAddressSuccess) => {
            if (createBillingAddressSuccess) {
              // after billing address is created, wait until cart is loaded, then document identifier can be handled safely
              this.activeCartService.clearCreateBillingAddressProcess();
              this.eventService.get(CartEvent)
                .pipe(
                  filter((event) => event instanceof CustomLoadCartSuccessEvent || event instanceof CustomLoadCartFailEvent),
                  take(1),
                )
                .subscribe((event) => {
                  if (event instanceof CustomLoadCartSuccessEvent) {
                    this.handleDocumentIdentifier();
                  }
                });
            }
          })
      );

      // Fetching countries for create billing address form
      this.countries$ = this.userAddressService.getDeliveryCountries().pipe(
        tap((countries: Country[]) => {
          if (Object.keys(countries).length === 0) {
            // (MLM), 20221027, Requested display all countries in billing address form instead of the countries of base store
            this.userAddressService.loadDeliveryCountriesAll();
          }
        })
      );
    }

    /**
     *
     * Get type of users
     */
    this.onBehalfOfTypes$ = this.customFriendsCheckoutService.getOnBehalfOfType();

    /**
     * Get selected friend from cart
     *
     */
    this.selectedFriendFromCart$ = this.customFriendsCheckoutService.getSelectedOnBehalfOfFromCart();

    if (this.allowFriends) {
      /**
       * Subscribe to Selected friend
       *
       */
      this.selectedFriend$ = this.customFriendsCheckoutService.getSelectedOnBehalfOf();
      this.subscriptions.add(
        this.selectedFriend$
          .subscribe((onBehalfOf: CustomFriendsCheckoutModel) => {
            this.selectedFriend = onBehalfOf;
            this.onBehalfOfAddress = onBehalfOf?.defaultAddress?.validCheckoutAddress ? [onBehalfOf.defaultAddress] : [];
            if (this.onBehalfOfAddress.length) {
              this.updateFriendSelector(onBehalfOf);
            }
          },
          ),
      );
    }


    if (this.isGuestCheckout) {
      this.subscriptions.add(
        this.deliveryAddressFromCart$.subscribe((deliveryAddressFromCart) => {
          /**
           * Load saved shipping address from cart
           */
          if (deliveryAddressFromCart) {
            this.defaultDeliveryAddress = deliveryAddressFromCart;
            this.addressList = [this.defaultDeliveryAddress];
            this.deliveryAddressArray$ = of([this.defaultDeliveryAddress]);
          } else {
            this.addressList = [];
          }
          this.deliveryAddressArray$ = of([this.defaultDeliveryAddress]);
        })
      );
    } else {
      this.subscriptions.add(
        combineLatest([
          this.getSupportedAddresses(),
          this.selectedFriendFromCart$,
          this.deliveryAddressFromCart$,
        ]).subscribe(([supportedAddresses, friend, deliveryAddressFromCart]) => {
          this.addressList = [];
          if (this.allowFriends) {
            this.onBehalfOfAddress = friend?.defaultAddress?.validCheckoutAddress ? [friend.defaultAddress] : [];
          }
          if (supportedAddresses?.length) {
            /**
             * Get current Supported user Delivery Address List
             *
             */
            this.addressList = supportedAddresses.filter((supportedAddress: Address) => {
              if (supportedAddress.validCheckoutAddress) {
                return supportedAddress;
              }
            });
            if (this.allowFriends && this.onBehalfOfAddress?.length) {
              this.addressList = [...supportedAddresses, this.onBehalfOfAddress[0]];
            }
            this.totalCards = this.addressList.length;
            this.filter?.updateValueAndValidity();

            /**
             * Load saved shipping address from cart
             */
            if (deliveryAddressFromCart) {
              if (!this.addressList.filter(currentAddress => currentAddress?.id === deliveryAddressFromCart?.id)?.length) {
                if (this.addressList?.length) {
                  this.selectDefaultAddress(this.addressList, this.defaultDeliveryAddress);
                }
              }
              this.defaultDeliveryAddress = deliveryAddressFromCart;
            } else {
              this.selectDefaultAddress(this.addressList, this.defaultDeliveryAddress);
            }

            if (this.allowFriends && this.verifiedFriend) {
              /**
               * Remove friend from cart if friend list is empty
               *
               */
              if (!this.friendsList?.length) {
                this.customFriendsCheckoutService.removeOnBehalfOf();
              }

              /**
               * Select default user address if fiend's address is not available
               *
               */
              if (this.friendsList?.length && this.onBehalfOfAddress?.length) {
                this.selectDefaultAddress(this.addressList, null);
              }
            }
          }
          this.deliveryAddressArray$ = of([this.defaultDeliveryAddress]);
        }),
      );
    }

    if (this.allowFriends) {
      /**
       * Load friend list for user
       *
       */
      this.customFriendsCheckoutService.loadOnBehalfOfCandidates();

      this.subscriptions.add(
        this.selectedFriendFromCart$.subscribe(
          (friend: CustomFriendsCheckoutModel) => {
            this.updateFriendSelector(friend);
          },
        ),
      );

      /**
       * Subscribe to friends list
       *
       */
      this.friendsList$ = this.customFriendsCheckoutService.getOnBehalfOfCandidates();
      this.subscriptions.add(
        this.friendsList$
          .pipe(map((friendList: CustomFriendsCheckoutModel[]) =>
            friendList.map((friend: CustomFriendsCheckoutModel) =>
            ({
              ...friend,
              name: friend.name + ' --- ' + friend.bankAccountNumber,
            }),
            ),
          ))
          .subscribe(friends => {
            this.totalCards += friends?.length;
            this.friendsList = friends;
          }),
      );
    }

    this.filteredAddresses$ = combineLatest([this.customTableData$.pipe(filter(item=>Boolean(item?.length))), this.filter$, this.selectedAddress$])
      .pipe(
        map(([addresses, filterString]) => addresses.filter(address => {
          const fullName = address.firstName + (address.lastName ? ' ' + address.lastName : '');
          return filterText([
            address.companyName,
            fullName,
            address.formattedAddress,
            address.phone,
            address.town,
          ], filterString);
        },
        )),
      );

      this.subscriptions.add(
        this.activeCartService.getActive().pipe(
          tap(cart => {
            this.cart = cart;
            if ( cart.totalPrice.value >= 3000) {
             this.requestBilling = true
            }
          } )
        ).subscribe()
      )
  }

  /**
   * Select default address if address is missing
   *
   */
  get selectedFriendList$(): Observable<Address[]> {
    if (this.allowFriends) {
      if (this.onBehalfOfAddress?.length) {
        return combineLatest([
          this.onBehalfOfAddress,
          this.selectedAddress$,
          this.translationService.translate('checkoutAddress.defaultShippingAddress'),
          this.translationService.translate('checkoutAddress.shipToThisAddress'),
          this.translationService.translate('addressCard.selected'),
        ]).pipe(
          tap(([addresses]) => {
            this.selectDefaultAddress([addresses], addresses);
          },
          ),
          map(([addresses]) => {
            this.verifiedFriend = true;
            return [addresses];
          }),
        );
      } else {
        this.verifiedFriend = true;
      }
    }
  }

  /**
   * Get delivery address from cart formatted as card
   *
   */
  get defaultDeliveryAddress$(): Observable<CardWithAddress[]> {
    let stateAddress = this.checkoutDeliveryAddressFacade.getDeliveryAddressState().pipe(
      filter((state) => !state.loading),
      map((state) => state.data)
    );
    return this.generateCards(this.deliveryAddressArray$, stateAddress);
  }

  /**
   * Observable with address formatted as cards
   *
   */
  get tableData$(): Observable<Address[]> {
    if (this.isGuestCheckout) {
      return this.generateTable(of(this.addressList), this.selectedAddress$);
    } else {
      return this.generateTable(this.getSupportedAddresses(), this.selectedAddress$);
    }
  }


  removeFilter(): void {
    this.filter?.patchValue('');
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.customValidateCartService.clearSimulateCart();
    this.customValidateCartService.simulateOrder(false);

    if (this.paymentTypeService && this.userCostCenterService && this.checkoutCostCenterService) {
      this.subscriptions.add(
        this.paymentTypeService
        .isAccountPayment()
        .pipe(distinctUntilChanged())
        .subscribe((isAccount: boolean) => this.isAccountPayment = isAccount));
    }

    if (!this.isGuestCheckout && !this.isAccountPayment) {
      this.userAddressService.loadAddressesBasestore();
    }

    /**
     * Subscription to validate cart
     *
     */
    this.subscriptions.add(
      combineLatest([
        this.customValidateCartService.getSimulateCartState(),
        this.customValidateCartService.getSimulateCartSuccess(),
        this.customValidateCartService.getSimulateCartError(),
      ]).subscribe(([, simulateCartSuccess, simulateCartError]) => {
        if (simulateCartError) {
          this.customValidateCartService.redirectToCartPage();
        }
        if (simulateCartSuccess) {
          this.onSuccess();
        }
      }),
    );

    this.subscriptions.add(
      this.eventService.get(CustomSetDocumentIdentifierSuccessEvent)
        .subscribe((success) => {
          if (success) {
            this.customValidateCartService.simulateOrder(false);
          }
        })
    );

    this.checkoutService.clearCheckoutData();
  }

  /**
   * Function to update selected user type
   *
   * @param customerData Update on behalf of user type
   */
  onUserTypeChange(customerData: any): void {
    this.showFriendListInput(customerData.showFriendList);
    if (customerData.code === 'me') {
      this.resetFormToDefault();
    }
  }

  /**
   * Show or hide user's address list
   *
   */
  toggleAddressList(): void {
    this.showAddressList = !this.showAddressList;
  }

  /**
  * (MLM), This action now not trigger any action. Because actions are triggered in next() action.
  */
  toggleBilling(): void {
    this.requestBilling = !this.requestBilling;
  }

  /**
  * (MLM), Removed set delivery address as billing address when toggle button. Unset billing address is maintained.
  */
  toggleSameBillingAsShippingAddress(): void {
    let hasBillingAddressSet: boolean;
    this.activeCartService.getBillingAddressFromCart()
      .subscribe((billingAddress) => hasBillingAddressSet = Boolean(billingAddress))
      .unsubscribe();
    this.sameBillingAsShippingAddress = !this.sameBillingAsShippingAddress;
    if (!this.sameBillingAsShippingAddress) {
      this.billingAddressLoaded = true;
      if (hasBillingAddressSet) {
        this.unsetBillingAddress();
      }
    } else {
      this.billingAddressLoaded = false;
      if (hasBillingAddressSet) {
        this.unsetBillingAddress();
      }
    }
    this.userAddressService.loadDeliveryCountriesAll();
  }

  countrySelected(country: Country): void {
    this.billingAddressForm['controls'].country['controls'].isocode.setValue(
      country.isocode,
    );
    this.billingAddressForm['controls'].postalCode?.updateValueAndValidity();

    // Fetching regions
    this.regions$ = this.userAddressService.getRegions(country.isocode).pipe(
      tap((regions: Region[]) => {
        const regionControl = this.billingAddressForm.get('region.isocode');
        if (regions && regions.length > 0) {
          regionControl.enable();
        } else {
          regionControl.disable();
        }
      }),
    );
  }

  regionSelected(region: Region): void {
    this.billingAddressForm['controls'].region['controls'].isocode.setValue(
      region.isocode,
    );
  }

  private setDeliveryAddressAsBillingAddress(): void {
    let addressId: string;
    this.activeCartService.getActive()
      .subscribe((cart) => addressId = cart.deliveryAddress?.id)
      .unsubscribe();
    this.customCheckoutPaymentService.setBillingAddress(addressId);
  }

  private unsetBillingAddress(): void {
    this.customCheckoutPaymentService.unsetBillingAddress();
  }

  getBillingAddressCardContent(billingAddress: Address): Observable<Card> {
    return this.translationService.translate('paymentForm.billingAddress').pipe(
      filter(() => Boolean(billingAddress)),
      map((textTitle) => ({
        title: textTitle,
        textBold: billingAddress.firstName + (billingAddress.lastName ? ' ' + billingAddress.lastName : ''),
        text: [billingAddress.formattedAddress, billingAddress.country.name],
      }))
    );
  }
  resetShowMyInformation(): void {
    this.showMyInformation = false;
    this.showMyInformationEn = false;
  }

  /**
   * Show or hide friends' list selectors
   *
   */
  showFriendListInput(value: boolean): void {
    this.showFriendList = value;
    if (!value) {
      this.onBehalfForm.controls.onBehalfOf.setValue('me');
    }
  }

  /**
   * Get On behalf of user
   *
   * @param onBehalfOfData on behalf of parameter
   */
  getOnBehalfOfAddress(onBehalfOfData: CustomFriendsCheckoutModel | null): void {
    const selectedFriend = onBehalfOfData?.uid;
    this.onBehalfForm.controls.friend.setValue(onBehalfOfData?.uid);
    if (selectedFriend) {
      this.customFriendsCheckoutService.updateOnBehalfOf(selectedFriend);
      this.showFriendListInput(true);
    } else {
      this.showFriendOnly = false;
    }
  }

  /**
   * Update form data
   *
   * @param onBehalfOf friend information
   */
  updateFriendSelector(onBehalfOf: CustomFriendsCheckoutModel): void {
    if (onBehalfOf) {
      this.onBehalfForm.patchValue({ onBehalfOf: 'friend', friend: onBehalfOf.uid });
      this.showFriendList = true;
      this.showAddressList = true;
      this.selectedFriend = onBehalfOf;
      if (this.defaultDeliveryAddress === onBehalfOf.defaultAddress) {
        this.changeAddress(onBehalfOf.defaultAddress);
      }
    } else {
      this.onUserTypeChange({ code: 'me' });
    }
  }

  /**
   * Reset form with default values
   *
   */
  resetFormToDefault(): void {
    this.getOnBehalfOfAddress(null);
    this.showFriendListInput(false);
    this.onBehalfForm.patchValue({ onBehalfOf: 'me', friend: null });
    this.customFriendsCheckoutService.removeOnBehalfOf();
    let userDefaultAddress = null;
    for (const currentAddress of this.addressList) {
      if (currentAddress.defaultAddress) {
        userDefaultAddress = currentAddress;
        break;
      }
    }
    this.changeAddress(userDefaultAddress);
    if (!this.isGuestCheckout) {
      this.userAddressService.loadAddressesBasestore();
    }
    let stateAddress = this.checkoutDeliveryAddressFacade.getDeliveryAddressState().pipe(
      filter((state) => !state.loading),
      map((state) => state.data)
    );
    this.generateCards(of([this.defaultDeliveryAddress]), stateAddress);
    this.removeFilter();
  }

  /**
   * Select default address if address is missing
   *
   * @param addresses List of addresses
   * @param selected Selected delivery address
   */
  selectDefaultAddress(addresses: Address[], selected: Address): void {
    if (!this.defaultDeliveryAddress && !this.defaultDeliveryAddress?.id) {
      if (!this.doneAutoSelect && addresses && addresses.length && (!selected || Object.keys(selected).length === 0)) {
        if (this.isAccountPayment) {
          if (addresses.length === 1) {
            this.selectAddress(addresses[0]);
            this.defaultDeliveryAddress = addresses[0];
          }
        } else {
          selected = addresses.find((address) => address.defaultAddress);
          if (selected) {
            this.selectAddress(selected);
            this.defaultDeliveryAddress = selected;
          } else {
            this.selectAddress(addresses[0]);
            this.defaultDeliveryAddress = addresses[0];
          }
        }
        this.doneAutoSelect = true;
      }
    } else {
      if (!this.doneAutoSelect) {
        this.selectAddress(this.defaultDeliveryAddress);
      }
      this.doneAutoSelect = true;
    }
  }

  /**
   * Generate cart content
   *
   * @param addressList List of addresses
   * @param selectedAddress Selected delivery address
   */
  generateCards(addressList: Observable<Address[]>, selectedAddress: Observable<Address>): Observable<CardWithAddress[]> {
    return combineLatest([
      addressList,
      selectedAddress,
      this.translationService.translate('checkoutAddress.defaultShippingAddress'),
      this.translationService.translate('checkoutAddress.shipToThisAddress'),
      this.translationService.translate('addressCard.selected'),
    ]).pipe(
      tap(([addresses, selected]) =>
        this.selectDefaultAddress(addresses, selected),
      ),
      map(([addresses, selected, textDefault, textShipTo, textSelected]) =>
        (addresses as any).map((address) => {
          return {
            address,
            card: this.getCardContent(
              address,
              selected,
              textDefault,
              textShipTo,
              textSelected,
            ),
          };
        }),
      ),
    );
  }

  generateTable(addressList: Observable<Address[]>, selectedAddress: Observable<Address>): Observable<Address[]> {
    return combineLatest([
      addressList,
      selectedAddress,
    ]).pipe(
      tap(([addresses, selected]) =>
        this.selectDefaultAddress(addresses, selected),
      ),
      map(([addresses]) => {
        return addresses;
      }),
    )
  }

  /**
   * Format Address data
   *
   */
  formatAddressData(address: Address): string {
    let region = '';
    if (address?.region?.name) {
      region = address.region.name;
    }

    const country = (address?.country?.name ? address.country.name : address?.country?.isocode);
    let formattedAddress = address?.line1;
    formattedAddress += concatFormattedAddress(address?.line2);
    formattedAddress += concatFormattedAddress(address?.town);
    formattedAddress += concatFormattedAddress(region);
    formattedAddress += concatFormattedAddress(country);
    formattedAddress += concatFormattedAddress(address?.postalCode);

    function concatFormattedAddress(addressData: string): string {
      return addressData?.length ? ', ' + addressData : '';
    }

    return formattedAddress;
  }

  /**
   * Generate delivery address cart content
   *
   */
  getCardContent(
    address: Address,
    selected: any,
    textDefaultShippingAddress: string,
    textShipToThisAddress: string,
    textSelected: string,
  ): Card {

    let region = '';
    if (address?.region && address?.region?.isocode) {
      region = address.region.name + ', ';
    }
    const showAlias = address?.companyName ? ' (' + address?.companyName + ')' : '';
    const country = (address?.country?.name ? address?.country.name : address?.country?.isocode);
    let textAddress = [
      address?.line1 + ', ' + address?.line2 + ', ' + address?.town + ', ' + region + country + ', ' + address?.postalCode,
    ];
    if (!this.currentBaseStore?.pickupStore) {
      textAddress = [...textAddress, address?.phone, address?.email];
    } else {
      textAddress = [...textAddress];
    }
    return {
      title: address?.defaultAddress ? textDefaultShippingAddress : '',
      textBold: address?.firstName + (address?.lastName ? ' ' + address?.lastName : '') + showAlias,
      text: textAddress,
      actions: [{ name: textShipToThisAddress, event: 'send' }],
      header: selected && selected?.id === address?.id ? textSelected : '',
    };
  }

  sameAddressAttributes(deliveryAddress: Address, billingAddress: Address): Boolean {
    return (deliveryAddress && billingAddress &&
      (deliveryAddress.firstName === billingAddress.firstName &&
        deliveryAddress.line1 === billingAddress.line1 &&
        deliveryAddress.line2 === billingAddress.line2 &&
        deliveryAddress.postalCode === billingAddress.postalCode &&
        deliveryAddress.town === billingAddress.town &&
        deliveryAddress.country.isocode === billingAddress.country.isocode &&
        deliveryAddress.phone === billingAddress.phone)
    )
  }

  next(): void {
    this.disableNextButton = true;
    if (this.requestBilling) {
      let hasBillingAddressSet: boolean;
      this.activeCartService.getBillingAddressFromCart()
        .subscribe((billingAddress) =>  hasBillingAddressSet = Boolean(billingAddress))
        .unsubscribe();
      if (!this.sameBillingAsShippingAddress) {
        if (hasBillingAddressSet) {
          this.handleDocumentIdentifier();
        } else if (this.billingAddressForm.valid) {
          this.customCheckoutPaymentService.createBillingAddress(this.billingAddressForm.value);
        } else {
          this.billingAddressForm.markAllAsTouched();
          this.disableNextButton = false;
        }
      } else if (hasBillingAddressSet) {
        this.handleDocumentIdentifier();
      } else {
        // (MLM), Creating new payment address instead of set the same (PK) as the shippong address
        this.customCheckoutPaymentService.createBillingAddress(this.defaultDeliveryAddress);
      }
    } else {
      let cartUser: User;
      let isocode: string;
      this.activeCartService.getBillingAddressFromCart()
        .subscribe((billingAddress) => isocode = billingAddress?.country?.isocode)
        .unsubscribe();
      this.activeCartService.getAssignedUser()
        .subscribe((user) => cartUser = {
          defaultAddress: {
            country: {
              isocode: isocode ? isocode : this.defaultDeliveryAddress?.country?.isocode
            }
          },
          documentIdentifier: '',
          ...user
        })
        .unsubscribe();
      this.activeCartService.validateDocIdentifierAndTotalAmount(cartUser)
      .subscribe({
        next: () => {
          let hasBillingAddressSet: boolean;
          this.activeCartService.getBillingAddressFromCart()
            .subscribe((billingAddress) =>  hasBillingAddressSet = Boolean(billingAddress))
            .unsubscribe();
          // (MLM), Since requestBilling button doesn't to any action, we need to check if requestBilling is unchecked and paymentAddress is set in order to remove it
          if (hasBillingAddressSet) {
            this.unsetBillingAddress();
          }
          super.next();
        },
        error: (e) => {
          this.disableNextButton = false;
          this.cdRef.detectChanges();
        }
      })
    }
  }

  shouldUseAddressSavedInCart(): boolean {
    return !!this.checkoutConfig?.checkout?.guest; }

    newAddress(address: Address | undefined): void {
    if (
      !address &&
      this.shouldUseAddressSavedInCart() &&
      this.selectedAddress
    ) {
      this.next();
    }

    if (!address) {
      return;
    }
    this.doneAutoSelect = true;

    this.checkoutDeliveryAddressFacade
    .createAndSetAddress(address)
    .pipe(take(1))
    .subscribe({
      complete: () => {
          this.checkoutDeliveryModesFacade.clearCheckoutDeliveryMode()
          this.addressFormOpened = false;
      },
      error: () => {
        this.onError();
        this.doneAutoSelect = false;
      },
    });
  }

  handleDocumentIdentifier(): void {
    if (this.requestBilling) {
      let cartUser: User;
      this.activeCartService.getAssignedUser()
        .subscribe((user) => cartUser = user)
        .unsubscribe();
      const documentIdentifier = this.documentIdentifierForm?.get('documentIdentifier')?.value;
      if (!cartUser.documentIdentifier) {
        let name: string;
        let isocode: string;
        let exclusiveRole = '';
        // get user data from billing address in cart
        let cartBillingAddress: Address;
        this.activeCartService.getBillingAddressFromCart()
          .subscribe((billingAddress) => cartBillingAddress = billingAddress)
          .unsubscribe();
          console.log('cartBillingAddress', cartBillingAddress);
        isocode = cartBillingAddress.country?.isocode;
        if (this.isGuestCheckout) {
          name = cartBillingAddress.firstName + ' ' + cartBillingAddress.lastName;
        } else {
          // get user data from user
          let loggedUser: User;
          this.userAccountFacade.get()
            .subscribe((user) => loggedUser = user)
            .unsubscribe();
          // (MLM), 20221027, Requested name and lastname validated against billing address form instead of customer
          name = cartBillingAddress.firstName + ' ' + cartBillingAddress.lastName;
          exclusiveRole = loggedUser?.exclusiveRole;
        }
        this.documentIdentifierForm.patchValue({
          name,
          exclusiveRole,
          defaultAddress: {
            country: {
              isocode
            }
          },
          documentIdentifier,
        }
        );
        if (this.documentIdentifierForm.valid) {
          this.activeCartService.setDocumentIdentifier(
            this.documentIdentifierForm.value
          ).subscribe(
            (response) => {
            console.log('Nif Success Validate:');
             sessionStorage.setItem('dCode', this.documentIdentifierForm.get('documentIdentifier').value);
            super.next();

            },
            (error) => {
              this.documentIdentifierForm.get('documentIdentifier').setErrors({});
              this.isNifValid = false;
              this.disableNextButton = false;
              this.cdRef.detectChanges();
                if (localStorage.getItem('spartacus⚿⚿language').replace(/"/g, '') ==  'en'){
                  this.showMyInformationEn = true;
                }else{
                  this.showMyInformation = true;
                }
              return;
            }
          );
        } else if (!this.documentIdentifierForm.valid) {
          this.disableNextButton = false;
          if(!this.documentIdentifierForm.get('documentIdentifier').value && this.cart.totalPrice.value >= 3000) {
            this.globalMessageService.add(
              { key: 'common.validateDocIdentifierAndTotalAmount' },
              GlobalMessageType.MSG_TYPE_ERROR
            );
          }
        }
        else if(cartBillingAddress?.region?.countryIso != 'ES') {
          if (!this.documentIdentifierForm.get('documentIdentifier').value) {
            this.disableNextButton = false;
            throw new Error('Document Identifier is required');
          }else {
            super.next();
          }
        }
        else {
          console.error('Validarion out of country');
          this.disableNextButton = false;
          this.isUpdating$ = null;
          this.hideNewAddressForm();
        }
      } else {
        super.next();
      }
    } else {
      console.error('Error');
    }
  }



  changeAddress(address): void {
    if (address) {
      this.selectAddress(address);
    } else {
      this.doneAutoSelect = false;
      if (!this.addressList.filter(addresses => addresses.id === this.defaultDeliveryAddress.id).length) {
        this.defaultDeliveryAddress = null;
      }
      this.selectDefaultAddress(this.addressList, this.defaultDeliveryAddress);
    }
    this.setDeliveryNotes({ deliveryNoteObservations: address?.deliveryNoteObservations });
  }

  setDeliveryNotes(extraData): void {
    this.activeCartService.getActiveCartId()
      .pipe(withLatestFrom(this.userIdService.getUserId()), take(1))
      .subscribe(([cartId, userId]) => {
        this.multiCartService.assignExtraData(cartId, userId, extraData);
      }, () => {
        console.error('Error setting delivery notes');
      });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.launchDialogService.clear(CUSTOM_LAUNCH_CALLER.VALIDATE_CART_SPINNER);
    this.launchDialogService.clear(CUSTOM_LAUNCH_CALLER.SIMULATE_CART_SPINNER);
    this.customValidateCartService.clearSimulateCart();
  }

  editAddressSubmit(address: Address): void {
    this.forceLoader = true;
    if (Boolean(address)) {
      this.checkoutDeliveryAddressFacade.createAndSetAddress(address);
    } else {
      this.forceLoader = false;
    }
  }

  // to avoid global message display
  selectAddress(address: Address): void {
    if (address?.id === getLastValueSync(this.selectedAddress$)?.id) {
      return;
    }
    this.setAddress(address);
  }
}
